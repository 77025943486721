.feedback-section {
    /* background-color: #f9f9f9; */
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
  }

  /* .feedback-section h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  } */

  .feedback-section form {
    display: flex;
    flex-direction: column;
  }
/*
  .feedback-section label {
    font-weight: bold;
    margin-top: 10px;
    color: #555;
  } */

  .feedback-section div {
    font-family: "Covered By Your Grace", cursive;
    font-size: x-large;
  }

  /* .feedback-secition h2 {
    font-size: small;
  } */

  .feedback-section label {
    font-family: "montserrat", sans-serif;
    font-size: large;
  }

  .feedback-section input[type="text"],
  .feedback-section input[type="tel"],
  .feedback-section input[type="email"],
  .feedback-section input[type="number"],
  .feedback-section textarea,
  .feedback-section select {
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    font-family: "Covered By Your Grace", cursive;
    font-size: x-large;
  }

  .feedback-section input[type="checkbox"],
  .feedback-section input[type="radio"] {
    margin-right: 10px;
    margin-left: 10px;
  }

  .feedback-section div {
    margin-bottom: 10px;
  }

  .feedback-section .checkbox-group,
  .feedback-section .radio-group {
    display: flex;
    flex-direction: column;
  }

  .feedback-section .checkbox-group div,
  .feedback-section .radio-group div {
    display: flex;
    align-items: center;
  }

  .feedback-section button {
    padding: 20px 20px;
    background-image: linear-gradient(35deg, #ff0040 1%, #ff9100);
    color: white;
    border: none;
    border-radius: 5px;
    width: 150px;
    margin-top: 20px;
    margin-left: calc(50% - 75px);
  }

  .feedback-section button:hover {
    /* background-image: linear-gradient(35deg, #ff9100 1%, #ff0040); */
    /* cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 48 48"><text y="32" font-size="32">💸</text></svg>'), auto; */
  }

  .feedback-section .thanks-message {
    color: green;
    margin-left: 10px;
    font-weight: bold;
  }

  span {
    white-space: nowrap;
  }