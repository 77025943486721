* {
  margin: 0;
  padding: 1%;
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
  font-family: Arial, sans-serif;
}

.App {
  position: relative;
  z-index: 1;
  font-family: "montserrat", sans-serif;
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../public/Sunset-illustration.webp') no-repeat center center/cover;
  z-index: -1;
  will-change: transform;
  pointer-events: none;
  transform: translateZ(0);
}

.section {
  padding: 20px;
  margin: 1% 1%;
  /* background: rgba(255, 255, 255, 0.9); */
  /* make a nice gradient fade background-color with 0.9 transparency */
  /* background: linear-gradient(90deg, rgba(201, 42, 42, 0.9), rgba(56, 99, 192, 0.9)); */
  background-image: linear-gradient(35deg, hsla(41, 100%, 50%, 0.9), hsla(45, 96%, 67%, 0.9));
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  min-width: 350px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.title-section {
  margin-top: 100px;
  text-align: center;
  background: none;
  font-family: "Covered By Your Grace", cursive;
  background-color: transparent;
  box-shadow: none;
  max-width: 8000px;
}

.title-text {
  font-family: 'Covered By Your Grace', cursive;
  /* font-size: 7em; */
  font-size: 20vw;
  /* font-size: 100px; */
  color: transparent;
  background-image: linear-gradient(35deg, #ff0040, #ffbf00);
  -webkit-background-clip: text;
  background-clip: text;
  position: relative;
  display: inline-block;
  white-space: nowrap;
}

.title-text::before {
  content: attr(data-text); /* Use the same text content */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  z-index: -1;
  filter: blur(10px);
  font-size: 20vw;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

h1 {
  /* margin-bottom: 20px; */
  font-family: "Covered By Your Grace", cursive;
  font-size: xx-large;
}

h2 {
  /* margin-bottom: 20px; */
  margin-top: 20px;
  font-family: "Covered By Your Grace", cursive;
  /* font-size: 2em; */
  font-size: x-large;
}

ul {
  margin-left: 30px
}
/* 
.link {
  font-family: "montserrat", sans-serif;
} */